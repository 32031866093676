import $ from '../vendor/jquery';
import 'slick-carousel';

function colorHelper(slick, slide) {
    const color = $(slide).find('[data-slick-arrow-color]').data('slickArrowColor') || '';

    if (slick.$prevArrow) {
        slick.$prevArrow.attr('data-color', color);
    }
    if (slick.$nextArrow) {
        slick.$nextArrow.attr('data-color', color);
    }
    if (slick.$dots) {
        slick.$dots.attr('data-color', color);
    }
}

$(function () {
    $('[data-widget="slider"]').each(function () {
        const $element = $(this);

        if (1 < $element.children().length) {
            $element
                .on('init', function (event, slick) {
                    colorHelper(slick, slick.$slides[slick.getCurrent()]);
                })
                .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    colorHelper(slick, slick.$slides[nextSlide]);
                })
                .slick({
                    adaptiveHeight: $element.data('adaptiveHeight') || false,
                    variableWidth: $element.data('variableWidth') || false,
                    infinite: $element.data('infinite') || false,
                    centerMode: 'center' === $element.data('alignment'),
                    slidesToShow: $element.data('slidesToShow') || 1,
                    slidesToScroll: $element.data('slidesToScroll') || $element.data('slidesToShow') || 1,
                    autoplay: $element.data('autoplay') || false,
                    autoplaySpeed: $element.data('autoplaySpeed') || 8000,
                    pauseOnHover: false,
                    fade: 'fade' === $element.data('animation'),
                    dots: $element.data('dots') || false,
                    arrows: $element.data('arrows') || false,
                    prevArrow: '<button type="button" class="slick-prev"><svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M27.852 20.75H9v-1.5h18.85L19.5 10.9l1.06-1.06 9.101 9.1L30.723 20l-1.06 1.063-9.102 9.1-1.061-1.06 8.352-8.353z" /></svg></button>',
                    nextArrow: '<button type="button" class="slick-next"><svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M27.852 20.75H9v-1.5h18.85L19.5 10.9l1.06-1.06 9.101 9.1L30.723 20l-1.06 1.063-9.102 9.1-1.061-1.06 8.352-8.353z" /></svg></button>',
                    responsive: $element.data('responsive'),
                });

            if ($element.data('controlledBy')) {
                const $controller = $($element.data('controlledBy'));

                if ('slider-nav' === $controller.data('widget')) {
                    $element.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                        $controller.slick('slickGoTo', nextSlide);
                    });
                } else if ('slider-thumbs' === $controller.data('widget')) {
                    $controller.children('a').first().addClass('current');
                    $element.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                        $controller.children('.current').removeClass('current');
                        $controller.children('a').eq(nextSlide).addClass('current');
                    });
                }
            }
        }
    });

    $('[data-widget="slider-nav"]').each(function () {
        const $element = $(this);

        if (1 < $element.children().length) {
            const $controlledElement = $($element.data('controls'));

            $element.slick({
                arrows: false,
                infinite: $element.data('infinite') || false,
                centerMode: 'center' === $element.data('alignment'),
                variableWidth: true,
                focusOnSelect: true
            });

            $element.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $controlledElement.slick('slickGoTo', nextSlide);
            });
        }
    });

    $('[data-widget="slider-thumbs"]').each(function () {
        const $element = $(this);

        if (1 < $element.children().length) {
            const $controlledElement = $($element.data('controls'));
            const $links = $element.children('a');

            $links.on('click', function (event) {
                $controlledElement.slick('slickGoTo', $links.index(event.currentTarget));

                event.blur();
            });
        }
    });
});
